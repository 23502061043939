import logo from './inProcess.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className='containerImage'>
        <img src={logo} alt="Página en desarrollo"/>
      </div>
    </div>
  );
}

export default App;
